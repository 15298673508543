import {
  SupplierFullListI,
  SupplierI,
  SupplierListI,
  SupplierErrI,
  SupplierListErrI,
} from '../Types/supplier';
import { get, post, patch, deleteRequest } from '../Utils/index';

export const addSupplier = async (data): Promise<SupplierI | SupplierErrI> => {
  return post(`/customer/supplier`, data).then((res) => res.data);
};

export const getSingleSupplier = async (data): Promise<SupplierI | SupplierErrI> => {
  const id = data?.queryKey[1];
  return get(`/customer/supplier/${id}`).then((res) => res.data);
};

export const updateSupplier = async (data): Promise<SupplierI | SupplierErrI> => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/supplier/${id}`, data).then((res) => res.data);
};

export const deleteSupplier = async (id) => {
  return deleteRequest(`/customer/supplier/${id}`);
};
export const restoreSupplier = async (id) => {
  return patch(`/customer/supplier/restore/${id}`);
};
export const getSingleDeletedSupplier = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/supplier/deleted/${id}`);
};

// export const getSuppliers = async (
//   data
// ): Promise<SupplierListI | { suppliers: { results: [] } } | SupplierListErrI> => {
//   const page = data?.queryKey[1] || 1;
//   const payload = { page, limit: 10 };
//   return get(`/customer/supplier`, payload).then((data) => data.data);
// };
export const hardDeleteSupplier = async (id) => {
  return deleteRequest(`/customer/supplier/hard-delete/${id}`);
};
export const getSuppliers = async (data) => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/supplier`, payload).then((data) => data.data);
};

export const getFullSuppliers = async (): Promise<SupplierFullListI | { suppliers: [] }> => {
  return get(`/customer/supplier/full-list`).then((data) => data.data);
};

export const supplierApi = {
  addSupplier,
  getSingleSupplier,
  updateSupplier,
  deleteSupplier,
  getSuppliers,
  getFullSuppliers,
  hardDeleteSupplier,
  getSingleDeletedSupplier,
  restoreSupplier,
};
