import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { supplierApi } from '../api/supplier';
import { supplier } from '../queryKeys/catalogue';
import { SupplierErrI } from '../Types/supplier';

export const useGetSupplier = (id, isEnable = true) => {
  return useQuery([supplier.SUPPLIER, id], supplierApi.getSingleSupplier, {
    enabled: isEnable,
    select: (data) => data?.supplier,
    onSuccess: () => {
      // const data = res?.data;
    },
    // onError: () => {
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetSuppliers = (isEnabled, page) => {
  return useQuery([supplier.SUPPLIERS, page], supplierApi.getSuppliers, {
    // initialData: { suppliers: { results: [] } },
    enabled: isEnabled,
    select: (res) => res?.suppliers,
    onSuccess: () => {
      // const data = res?.data;
    },
    onError: (err: any) => {
      return err?.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetFullSuppliers = (isEnabled) => {
  return useQuery([supplier.SUPPLIERS + 'FULL'], supplierApi.getFullSuppliers, {
    initialData: { suppliers: [] },
    enabled: isEnabled,
    select: (data) => data?.suppliers,
    onSuccess: () => {
      // const data = res?.data;
    },
    // onError: () => {
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useAddSupplier = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(supplierApi.addSupplier, {
    onSuccess: () => {
      // const data = res.data;
      // toast.success(data.message);
      queryClient.invalidateQueries([supplier.SUPPLIERS]);
      // if (isOngoing) {
      navigate('/supplier-management');
      // } else {
      // navigate('/catalogue', { state: { activeTab: '7' } });
      // }
    },
    onError: (err: SupplierErrI) => {
      return err;
      // const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useUpdateSupplier = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(supplierApi.updateSupplier, {
    onSuccess: (res, id) => {
      // const data = res.data;
      // toast.success(data.message);
      queryClient.invalidateQueries([supplier.SUPPLIER, id]);
      queryClient.invalidateQueries([supplier.SUPPLIERS]);
      navigate(`/supplier-management`, { state: { activeTab: '7' } });
    },
    onError: (err: any) => {
      return err;
      // const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useDeleteSupplier = (isOngoing) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(supplierApi.deleteSupplier, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([supplier.SUPPLIERS]);
      queryClient.removeQueries([supplier.SUPPLIER, id]);
      // toast.success(data.message);
      if (isOngoing) {
        navigate('/supplier-management');
      } else {
        navigate('/catalogue', { state: { activeTab: '7' } });
      }
    },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
  });
};
export const useRestoreSupplier = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(supplierApi.restoreSupplier, {
    onSuccess: () => {
      queryClient.invalidateQueries([supplier.SUPPLIERS]);
      // queryClient.invalidateQueries([part.PART, id]);
      //toast.success(data.message);
      navigate('/deleted-items');
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  });
};

export const useHardDeleteSupplier = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(supplierApi.hardDeleteSupplier, {
    onSuccess: () => {
      queryClient.invalidateQueries([supplier.SUPPLIERS]);
      // queryClient.invalidateQueries([part.PART, id]);
      navigate('/deleted-items');
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  });
};
